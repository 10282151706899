import { useConnectors } from '@starknet-react/core';

export default function DisconnectWallet({ account }: { account: string }) {
    const { disconnect } = useConnectors();
    return (
        <div>
            <p>Congrats <b className=' text-white font-black bg-gradient-to-r from-pink-500 to-yellow-500  px-2'>{account.charAt(0)+account.charAt(1)+"..." + account.substring(account.length - 6)}</b> you are connected to MVMT!</p>
            <p className='text-xs'>You will need the early bird NFT to get furter. Click here to be added to the waitlist.</p>
            <br />
            <button className='bg-gray-200 rounded-full h-16 w-60 mx-2 bg-gradient-to-r justify-self-end hover:text-white hover:from-pink-500 hover:to-yellow-500' onClick={() => disconnect()}>Disconnect</button>
        </div>
    )
}