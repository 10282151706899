import { useStarknet } from '@starknet-react/core';
import { Navigapp } from '../components/home';
import { MaxBuyPerTx, MaxSupplyForMint, PaymentTokenAddress, ProjectNFTAddress, PublicSaleOpen, ReservedSupplyForMint, UnitPrice, WhitelistedSaleOpen, WhitelistMerkleRoot } from '../components/NFT';
import { ConnectButton, DisconnectButton } from '../components/Wallet';
import { MINTER_CONTRACT_ADDRESS } from '../contracts/addresses';

function Testnet() {
    const { account } = useStarknet();

    return (

        <>
        <div className='w-screen grid place-content-center justify-items-center'>
        <div className="max-w-screen-2xl  w-screen  grid  justify-content-center place-self-center justify-self-center px-20  min-h-screen content-start position relative">
        <Navigapp />
            <div className='bg-white w-full grid  place-content-center z-10 mt-20'>
                <div className=' w-full grid justify-content-center' >
                    <h1 className='font-extrabold uppercase'>Welcome on the 8mvmt app</h1>
                    <br />
                    { undefined !== account ? <DisconnectButton account={account} /> : <ConnectButton /> }
                </div>
                </div>
            </div>
            </div>
        </>

        
    );
}

export default Testnet;
